import jwt_decode from "jwt-decode";
const TOKEN_KEY = "quiickToken";

export const login = () => {
  localStorage.setItem(TOKEN_KEY, "TestLogin");
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    return true;
  }
  return false;
};

export const getUserData = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!!token) {
    const decode = jwt_decode(token)
    if(!!decode) return decode;
  }
  return false;
};
