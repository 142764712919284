import * as actionTypes from "./authTypes";

const INITIAL_STATE = {
  session: [],
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    case actionTypes.SEND_EMAIL:
      return {
        ...state,
        // profiles: action.data,
      };

    case actionTypes.OPEN_DRAWER:
      return {
        ...state,
        openDrawer: action.data,
      };

    case actionTypes.CURRENT_PROFILE:
      return {
        ...state,
        profile: action.data,
      };

    default:
      return state;
  }
};

export default AuthReducer;
