import http from "../../config/axios";

class AuthService {
  validatePhoneExist(data) {
    return http.post(`/auth/validations/validate-phone`, data);
  }
  sendSms(data) {
    return http.post("/auth/validations/send-sms", data);
  }
  validatePhoneCode(data) {
    return http.post("auth/validations/validate-sms", data);
  }
  sendEmail(data) {
    return http.post("/auth/validations/send-email", data);
  }
  validateEmailExist(email) {
    return http.get(`/auth/validations/email-exist/${email}`);
  }

  validateUsernameExist(username) {
    return http.get(`/auth/validations/username-exist/${username}`);
  }
  validateEmailCode(data) {
    return http.post("auth/validations/email-code", data);
  }
  register(data) {
    return http.put(`auth/register`, data);
  }
  login(data) {
    return http.post(`auth/login`, data);
  }
  deleteAll() {
    return http.delete(`/tutorials`);
  }
  findByTitle(title) {
    return http.get(`/tutorials?title=${title}`);
  }
}
export default new AuthService();
