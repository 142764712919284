import * as actionTypes from "./iconTypes";

const INITIAL_STATE = {
  session: [],
  icons: [],
  icon:null,
  save: false,
  isPro: false,
};

const IconReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL:
      return {
        ...state,
        icons: action.payload.data,
      };

    case actionTypes.SET_ONESOCIAL:
      return {
        ...state,
        icon: state.icons.social.find((p) => p.id.includes(action.payload)),
      };
    case actionTypes.SET_ONECONTACT:
      return {
        ...state,
        icon: state.icons.contacts.find((p) => p.id.includes(action.payload)),
      };

    default:
      return state;
  }
};

export default IconReducer;
