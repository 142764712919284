import React ,{ Suspense} from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import { Provider } from "react-redux";
import store from "./redux/store";
import "./i18n";
import Loader from "./components/loader/Loader";
import { CookiesProvider } from "react-cookie";


ReactDOM.render(
  <Provider store={store}>
    
    <Suspense fallback={<Loader />}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);


