import React, {useState, useEffect} from 'react'
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Logo from "../../../assets/logo/svg/black.svg"
import tokenAuth from "../../../config/token"
import  "./style.css"
// Component
import { isLogin } from "../../../components/helpers/utils";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {login} from "../../../redux/auth/authActions";



// Init
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://quiick.do/">
        Quiick.do
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const theme = createTheme({
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
    ].join(","),
  },
});

export default function Login() {
  // Redux
  const dispatch = useDispatch();
  // const store = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);  
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState({
     type: "",
     text: "",
   });
   const [identity, setIdentity] = React.useState("");
   const [pwd, setPwd] = React.useState("");

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setOpen(false);
    };

    // useEffect(() => {
    //       if (!!localStorage.getItem("quiickToken")) {
    //          window.location.href="/profile";
    //       }
    // }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
   
    if (identity === "" || pwd === "") {
      setMessage({
        type: "warning",
        text: "Ingresa tus credenciales para acceder",
      });
       setOpen(true);
      return;
    }
    const data = {
      identity: identity,
      password: pwd,
    };

     dispatch(login(data)).then((val) => {
       if (val.response === "ok") {
          localStorage.setItem("quiickToken", JSON.stringify(val.data.token));
           tokenAuth(val.data.token);
          window.location.href = `/profile`;
        
       } else {
       setMessage({
         type: "error",
         text: val.data,
       });
       setOpen(true);
       }
     });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}> */}
            <img src={Logo} className="logo-login" />
            {/* </Avatar> */}
            {/* <Typography component="h1" variant="h5">
              Sign in
            </Typography> */}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                // required
                fullWidth
                id="identity"
                label="Correo / Usuario"
                name="identity"
                onChange={(e) => setIdentity(e.target.value.toLocaleLowerCase())}
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                // required
                fullWidth
                name="password"
                label="Clave"
                type="password"
                onChange={(e) => setPwd(e.target.value)}
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Recuérdame"
              />
              <Button
                className="btn-primary-purple"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Acceder
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Olvidé mi contraseña
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/auth/signup" variant="body2">
                    Deseo unirme a <b>Quiick</b>
                  </Link>
                </Grid>
              </Grid>
            </Box>
            {loading ? <LinearProgress style={{ width: "100%" }} /> : null}
            <Snackbar
              open={open}
              autoHideDuration={4000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleClose}
                severity={message.type}
                sx={{ width: "100%" }}
              >
                {message.text}
              </Alert>
            </Snackbar>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
          <br />
        </Container>
      </ThemeProvider>
    </>
  );
}

