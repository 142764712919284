import * as actionTypes from "./profileTypes";

const INITIAL_STATE = {
  session: [],
  profile: null,
  publicProfile: null,
  logs:null,
  save: false,
  isPro: false,
};

const ProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_ONE:
      return {
        ...state,
        profile: action.payload.data,
      };

    case actionTypes.GET_LOGS:
      return {
        ...state,
        logs: action.payload.data,
      };

    case actionTypes.GET_ONEPUBLIC:
      return {
        ...state,
        publicProfile: action.payload.data,
      };

    case actionTypes.UPDATE_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };

    case actionTypes.SET_SAVE:
      return {
        ...state,
        save: action.payload,
      };

    case actionTypes.SET_MODAL:
      return {
        ...state,
        isPro: action.payload,
      };

    default:
      return state;
  }
};

export default ProfileReducer;
