import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Typed from 'react-typed';
import Lottie from 'react-lottie-player';
import CssBaseline from '@mui/material/CssBaseline';

import lottieJson from '../../assets/animate/66503-card-encode.json';

export default function CircularIndeterminate() {
  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          width: '100%',
          marginTop: '60%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* <WifiLoader
            background={"transparent"}
            desktopSize={"100px"}
            mobileSize={"100px"}
            text={"Quiick"}
            backColor="#000000"
            frontColor="#303030"
          /> */}
        {/* <div className='fade-in'> */}

        {/* </div> */}
        <Lottie
          loop
          animationData={lottieJson}
          play
          style={{ width: 350, height: 350 }}
          speed={1}
        />
        {/* <Typed
          className='loading-text'
          strings={[
            'Obteniendo Perfil...',
            'Obteniendo Fotos...',
            'Obteniendo links...',
          ]}
          typeSpeed={50}
          backSpeed={60}
          backDelay={2}
          smartBackspace
          loop
        ></Typed> */}
        {/* <img src={Logo} alt='logo-quiick' className='logo-login' /> */}
        {/* <Skeleton />
          <Skeleton variant="rectangular" height={118} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
          <Skeleton variant="text" /> */}
      </Box>
    </Container>
  );
}
