import { combineReducers } from "redux";

import authReducer from "./auth/authReducer";
import profileReducer from "./profile/profileReducer";
import iconReducer from "./icon/iconReducer";


const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  icon: iconReducer,
});

export default rootReducer;
